import TableComponent from "components/TableComponent";
import { ModalsType } from "containers/ModalManager/ModalManager";
import usePrevious from "HOC/UsePrevious";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button, Table } from 'semantic-ui-react';
import { modalOperation } from "store/actions";
import { deleteUserRequest, requestUserList } from "store/list";
import { updateAppData } from "store/rext";
import { getAppState, getUserListState } from "store/selectors";
import { Column, User } from "../../interface";
import { APP_NAME, sortOptions } from "../../utils/global";

const columns: Column[] = [
  { name: '', value: 'edit', sort: false, width: 1 },
  { name: '', value: 'delete', sort: false, width: 1 },
  { name: 'ID', value: 'id', sort: true },
  { name: 'NT Login (Email)', value: 'email', sort: true },
  { name: 'Admin', value: 'role', sort: true }
];

const UserList = () => {

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { practice_id } = useParams();
  const dispatch = useDispatch();
  const { data: userList, fetching: userListFetching, operationProgress, operationError } = useSelector(getUserListState);
  const prevsOperationInProgress = usePrevious(operationProgress);
  const [deleteUserId, setDeleteUserId] = useState<string>(undefined!);
  const { data: appState } = useSelector(getAppState);


  const handleConfirmDelete = (userId: number) => {
    setDeleteUserId(userId.toString());
    dispatch(modalOperation.showModal(ModalsType.AlertModal, {
      onSave: () => {
        dispatch(modalOperation.hideModal())
        if(practice_id) {
          dispatch(deleteUserRequest(practice_id, userId));
        }
      },
      onClose: () => {
        setDeleteUserId(undefined!);
        dispatch(modalOperation.hideModal())
      },
      message: <Fragment>
        <p>Are you sure you want to delete this user?</p>
        <p>This operation cannot be undone.</p>
      </Fragment>,
      primaryBtnTitle: "Yes",
      secondaryBtnTitle: "No"
    }))
  }
  
  const renderUserRow = (user: User, index: number) => (
    <Table.Row key={index}>
      <Table.Cell><Button size='mini' secondary onClick={() => navigate(`edit/${user?.id}`, { state: user })}>Edit</Button></Table.Cell>
      <Table.Cell><Button size='mini' color="red" onClick={() => handleConfirmDelete(user?.id)}>Delete</Button></Table.Cell>
      <Table.Cell>{user?.id}</Table.Cell>
      <Table.Cell>{user?.email || '--'}</Table.Cell>
      <Table.Cell textAlign='center'>
        {
          user.role === 'ADMIN' ?
            <span className="text-bold text-x2lg text-success-color">&#10003;</span>
            :
            <span className="text-bold text-x1lg text-danger-color">&#x2715;</span>
        }
      </Table.Cell>
    </Table.Row>
  )

  const sortUserList: User[] = useMemo(() => {
    const column = searchParams.get('col')!;
    const direction = searchParams.get('dir');
    if(column && direction) {
      return sortOptions(userList, column, direction === "descending" ? 1 : 0, column === 'email')
    }
    return userList
  }, [ userList, searchParams]);

  useEffect(() => {
    if (practice_id) {
      dispatch(requestUserList(practice_id))
    }
  }, [practice_id]);


  useEffect(() => {
    if(prevsOperationInProgress && !operationProgress && !operationError) {
        if(deleteUserId === appState?.user?.id.toString()) {
            dispatch(updateAppData());
            navigate("/");
        }
        setDeleteUserId(undefined!);
    }
}, [ prevsOperationInProgress, operationProgress, operationError])

  return (
    <Fragment>
      <div className="width-70">
        <Button secondary onClick={() => navigate('add')} size='tiny'>
          Add a User
        </Button>

        <h5 className="margin-t-8 margin-b-9 text-capitalize">current {APP_NAME} users :</h5>
        <TableComponent noDataMessage="There are currently no users."
          fetching={!!userListFetching} columns={columns} rows={sortUserList} >
          {
            sortUserList?.map(renderUserRow)
          }
        </TableComponent>
      </div>
    </Fragment>
  );
};

export default UserList;