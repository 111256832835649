import { FormDropdown, FormInput } from "components/FormInputs";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from 'semantic-ui-react';

const roleOptions = [
    { key: 1, text: 'Yes', value: 'ADMIN' },
    { key: 2, text: 'No', value: 'USER' }
];

const AddUserForm = ({ userData, isEdit, handleCancel, onSubmit, isLoading }: any) => {

    const { register, handleSubmit, setValue, getValues, reset, formState: { errors }, trigger } = useForm();

    useEffect(() => {
        if (isEdit) {
            reset(userData || {});
        } else {
            reset({});
        }
    }, []);

    return (

        <Form size="small" onSubmit={handleSubmit(onSubmit)}>
            <FormInput
                label='NT Logon Email'
                name='email'
                errors={errors}
                register={register}
                validation={{ required: true, pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ }}
                disabled={isEdit}
            />

            <FormDropdown
                label='User is Admin'
                name='role'
                options={roleOptions}
                errors={errors}
                register={register}
                validation={{ required: true }}
                getValues={getValues}
                setValue={setValue}
                trigger={trigger}
            />

            <div className="margin-t-8 flex flex-justify-end">
                <div className="margin-r-2">
                    <Button type="button" disabled={isLoading} size='tiny' onClick={handleCancel}>Cancel</Button>
                </div>
                <Button type='submit' loading={isLoading} size='tiny' primary>Save</Button>
            </div>
        </Form>

    );
};

export default AddUserForm;