import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { getAppState } from 'store/selectors';
import { IPractice } from '../interface';

export const AdminRoute = () => {
    const location = useLocation();
    const { practice_id } = useParams();
    const { data: appData } = useSelector(getAppState);
    const practice: IPractice = useMemo(() => {
        return appData?.list?.find((appPractice: IPractice) => practice_id && appPractice.id === parseInt(practice_id))
    }, [practice_id, appData]);

    if (!practice) {
        return null; 
    }

    return practice.role === 'ADMIN'
        ? <Outlet />
        : <Navigate to="/" replace state={{ from: location }} />;

}
