import { Fragment, useMemo } from "react";
import AdminMenuItems from "./AdminMenuItems";
import { NavLink, Outlet, useParams } from "react-router-dom";
import { List } from "semantic-ui-react";
import { IPractice } from "../../interface";
import { MenuType } from "./menuType";
import { useSelector } from "react-redux";
import { getAppState } from "store/selectors";


const menuItems: MenuType[] = [
  { name: 'change practice', link: '/' },
  { name: 'view price list', link: 'price-list' },
  { name: 'add charge back', link: 'charge-back' },
  { name: 'new search', link: 'search' }
];

const Menu = () => {

  const { practice_id } = useParams();
  const { data: appData } = useSelector(getAppState);
  const practice: IPractice = useMemo(() => {
    return appData?.list?.find((appPractice: IPractice) => practice_id && appPractice.id === parseInt(practice_id))
  }, [practice_id, appData]);

  const renderMenuItem = (item: MenuType, index: number) => {
    return (
      <List.Item key={index}>
        <NavLink to={item?.link} className={(navData) => 'text-bold text-capitalize text-hover-underline text-hover-primary ' + (navData.isActive ? 'text-primary-color' : 'text-grey-color')}>
          {item?.name}
        </NavLink>
      </List.Item>
    )
  }

  if (!practice) {
    return null;
  }

  return (

    <Fragment>
      <div className="text-align-center">
        {practice.role === 'ADMIN' && <AdminMenuItems practiceId={practice_id} />}

        <div className="padding-t-2 margin-b-6">
          <span className="text-bold text-default-color">
            {practice?.name} - &nbsp;
          </span>

          <List divided horizontal>
            {
              menuItems.map(renderMenuItem)
            }
          </List>
        </div>
      </div>

      <div className="margin-t-10 padding-l-14 padding-r-14">
        <Outlet context={practice} />
      </div>
    </Fragment>

  );
}

export default Menu;
