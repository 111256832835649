import { Fragment } from "react";
import { Form, Checkbox } from "semantic-ui-react";

export function FormCheckbox({ onChange, ...props }: any) {

  const { label, name, register, getValues, setValue, trigger, handleChange } = props;
  const value = getValues()[name] || false;

  const handleCheckboxChange = handleChange || (
    async (e: any, { checked }: any) => {
      setValue(name, checked);
      await trigger([name]);
    }
  )

  return (
    <Fragment>
      <Form.Field className="flex flex-row padding-t-1">
        <label>{label}</label>
        <Checkbox checked={value} className="margin-l-3" {...register(name)}
          onChange={handleCheckboxChange} />
      </Form.Field>
    </Fragment>
  );
}
