import { Fragment } from "react";
import { Dropdown, Form } from "semantic-ui-react";
import { FormErrors } from "./FormErrors";

export function FormDropdown({ onChange, ...props }: any) {

  const { label, name, errors, register, validation, options, multipleSelection, placeholder, handleResetValue, resetLabel, getValues, setValue, trigger, handleChange } = props;
  const value = getValues()[name];

  const handleDropdownChange = handleChange || (
    async (e: any, { value : dropdownValue }: any) => {
      setValue(name, dropdownValue);
      await trigger([name])
    }
  )

  return (
    <Fragment>
      <Form.Field error={errors && !!errors[name]}>
        <label className={validation && validation.required && 'required'}>{label}</label>
        <div className="width-100 flex flex-row flex-align-center">
          <Dropdown
            placeholder={placeholder || label}
            fluid
            selection
            multiple={multipleSelection}
            options={options}
            value={value}
            clearable
            search
            selectOnBlur={false}
            {...register(name, validation)}
            onChange={handleDropdownChange}
          />
          {handleResetValue && <a href="#" className="text-underline text-inline margin-l-2" onClick={handleResetValue}>{resetLabel || "Reset"}</a>}
        </div>
        {
          errors &&
          <FormErrors
            label={label}
            name={name}
            errors={errors}
          />
        }
      </Form.Field>
    </Fragment>
  );
}
