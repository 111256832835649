import { combineReducers } from 'redux';

import modalState, { ModalState } from "./modal";
import applicationContextReducer, { ApplicationContextState } from "./applicationContext"
import { IRextReducer } from 'baseProvider/rext';
import { appRext, chargeBackDropdownRext, fetchChargeBackRext, manageChargeBackRext, managePriceListRext, manageUserDataRext, searchDropdownRext } from 'store/rext';
import { IListReducer } from 'baseProvider/list';
import { priceList, userList, viewPostHistoryList } from 'store/list';

export type { ModalState } from "./modal";

export interface ApplicationState {
  modalState: ModalState;
  applicationContext: ApplicationContextState;
  appState: IRextReducer;
  userListState: IListReducer; 
  viewPostHistoryState: IListReducer;
  priceListState: IListReducer;
  searchDropdownState:IRextReducer;
  chargeBackDropdownState:IRextReducer;
  managePriceListState:IRextReducer;
  manageChargeBackState:IRextReducer;
  fetchChargeBackState: IRextReducer;
  manageUserState:IRextReducer;
}

const rootReducer = () => combineReducers<ApplicationState>({
  modalState: modalState,
  applicationContext: applicationContextReducer,
  appState: appRext.reducers,
  userListState: userList.reducers,
  viewPostHistoryState: viewPostHistoryList.reducers,
  priceListState: priceList.reducers,
  searchDropdownState: searchDropdownRext.reducers,
  chargeBackDropdownState:chargeBackDropdownRext.reducers,
  managePriceListState:managePriceListRext.reducers,
  manageChargeBackState: manageChargeBackRext.reducers,
  fetchChargeBackState: fetchChargeBackRext.reducers,
  manageUserState: manageUserDataRext.reducers
});

export default rootReducer;