import { fetchRequest, getFullUrl } from "Api";
import { Fragment, MutableRefObject, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "semantic-ui-react";
import { getBaseUrl, getBaseUrlAPIKEY, getToken } from "store/selectors";

interface Props {
  url: string;
  urlParams: any;
  method: any;
  queryParams: any;
  body: any;

}

const SAFE_URL_PATTERN = /^(blob:(?:https?|ftp|file):|[^&:/?#]*(?:[/?#]|$))/gi;

function _sanitizeUrl(url: string): string {
  url = String(url);
  if (url === "null" || url.length === 0 || url === "about:blank") return "about:blank";
  if (url.match(SAFE_URL_PATTERN)) return url;

  return `unsafe:${url}`;
}

function sanitizeUrl(url = "about:blank"): string {
  return _sanitizeUrl(String(url).trim());
}

function Download({
  url, method, queryParams, body, urlParams
}: Props) {
  const downloadRef: MutableRefObject<HTMLAnchorElement> = useRef(null!);
  const token = useSelector(getToken);
  const baseUrl = useSelector(getBaseUrl);
  const apiKey = useSelector(getBaseUrlAPIKEY);
  const [fetching, setFetching] = useState<boolean>(false);

  const handleDownload = async () => {
    if (!fetching) {
      try {
        setFetching(true);
        const response = await fetchRequest(getFullUrl(baseUrl, url, { urlParams, queryParams }), token, method, apiKey, body);
        const blob: Blob = await response.blob();
        downloadRef.current.href = sanitizeUrl(URL.createObjectURL(blob));
        downloadRef.current.download = `chargetrack${new Date().getTime()}.xlsx`;
        downloadRef.current.click();
        setFetching(false);
      } catch (error: any) {
        setFetching(false);
        toast.error(error.message)
      }
    }
  }

  return (
    <Fragment>
      <Button size="mini" onClick={handleDownload} secondary loading={fetching}>
        Export To Excel
      </Button>
      <a ref={downloadRef}></a>
    </Fragment>
  )
}

export default Download;