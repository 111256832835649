
import { createRext, IRext } from "baseProvider/rext";
import { getBaseUrl, getBaseUrlAPIKEY as getXAPIToken, getToken } from "store/selectors";
import { transformItemToDropdownOption } from "utils/global";

export const appRext = createRext({
    getBaseUrl,
    getToken,
    getXAPIToken,
    identity: "appRext"
})

export const searchDropdownRext: IRext = createRext({
    getBaseUrl,
    getToken,
    getXAPIToken,
    identity: "searchDropdownRext",
    transformation: (item: any) => {
        const options: any = {};
        Object.keys(item.dropdowns || {}).forEach((key: string) => {
            if (key === "locations" || key === "users") {
                options[key] = [{
                    key: "ALL",
                    value: "ALL",
                    text: "All"
                }, ...transformItemToDropdownOption(item.dropdowns[key] || [])]
            } else {
                options[key] = transformItemToDropdownOption(item.dropdowns[key] || [])
            }

        })
        return options;
    }
})

export const chargeBackDropdownRext: IRext = createRext({
    getBaseUrl,
    getToken,
    getXAPIToken,
    identity: "chargeBackDropdownRext",
    transformation: (item: any) => {
        const options: any = {};
        Object.keys(item.dropdowns || {}).forEach((key: string) => {
            if (key === "locations" || key === "users") {
                options[key] = [{
                    key: "All",
                    value: "All",
                    text: "All"
                }, ...transformItemToDropdownOption(item.dropdowns[key] || [])]
            } else {
                options[key] = transformItemToDropdownOption(item.dropdowns[key] || [])
            }
        })
        return options;
    }
})

export const managePriceListRext: IRext = createRext({
    getBaseUrl,
    getToken,
    getXAPIToken,
    identity: "managePriceListRext"
})

export const fetchChargeBackRext: IRext = createRext({
    getBaseUrl,
    getToken,
    getXAPIToken,
    identity: "fetchChargeBackRext"
})

export const manageChargeBackRext: IRext = createRext({
    getBaseUrl,
    getToken,
    getXAPIToken,
    identity: "manageChargeBackRext"
})

export const postToAccountingRext: IRext = createRext({
    getBaseUrl,
    getToken,
    getXAPIToken,
    identity: "postToAccountingRext"
})

export const manageUserDataRext: IRext = createRext({
    getBaseUrl,
    getToken,
    getXAPIToken,
    identity: "manageUserDataRext"
})
