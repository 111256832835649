import usePrevious from "HOC/UsePrevious";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addEditUserRequest } from "store/list";
import { requestUserData } from "store/rext";
import { getUserData } from "store/selectors";
import { APP_NAME } from "../../../utils/global";
import Loading from "components/Loading";
import AddUserForm from "./AddUserForm";
import { toast } from "react-toastify";

interface User {
    email: string;
    role: string;
}

const AddUser = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user_id, practice_id }: any = useParams();
    const [isEdit, setEdit] = useState<boolean>(false);
    const { data: userData, isUpdated: isUserUpdating, error: updationError, fetching } = useSelector(getUserData);
    const wasUserUpdating = usePrevious(isUserUpdating);

    useEffect(() => {
        if (user_id) {
            dispatch(requestUserData(practice_id, user_id));
            setEdit(true);
        } else {
            setEdit(false);
        }
    }, [user_id])

    useEffect(() => {
        if (wasUserUpdating && !isUserUpdating && !updationError) {
            toast.info(isEdit ? "User Updated SuccessFully" : "User has been added");
            navigateBack();
        }
    }, [wasUserUpdating, isUserUpdating])


    const onSubmit = (data: User) => {
        dispatch(addEditUserRequest(isEdit, practice_id, user_id, data));
        navigate(`/practice/${practice_id}/user-list`);
    }

    const navigateBack = () => {
        navigate(-1);
    }

    return (

        <div className="width-30">
            <h5 className="margin-b-9">{isEdit ? 'Edit an existing' : 'Add a new'} {APP_NAME} User :</h5>
            {(!isEdit || (isEdit && !fetching)) && <AddUserForm
                userData={isEdit ? userData : {}}
                isEdit={isEdit}
                handleCancel={navigateBack}
                onSubmit={onSubmit}
                isLoading={isUserUpdating} />
            }
            {
                fetching && <Loading />
            }
        </div>
    );
};

export default AddUser;