import { ModalsType } from "containers/ModalManager/ModalManager";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { List } from "semantic-ui-react";
import { modalOperation } from "store/actions";
import { postToAccounting } from "store/rext";
import { MenuType } from "./menuType";

const menu: MenuType[] = [
  { name: 'user list', link: 'user-list', isLink: true },
  { name: 'view post history', link: 'post-history', isLink: true },
  { name: 'post to accounting all reviewed records', link: 'charge-back', isLink: false },
  { name: 'edit price list', link: 'admin-price-list', isLink: true }
];

const AdminMenuItems = ({ practiceId }: any) => {

  const dispatch = useDispatch();

  const handleConfirmModal = () => {
    dispatch(modalOperation.showModal(ModalsType.AlertModal, {
      onClose: () => {
        dispatch(modalOperation.hideModal())
      },
      onSave: () => {
        dispatch(postToAccounting(practiceId));
        dispatch(modalOperation.hideModal());
        toast.info("All charge backs posted successfully");
      },
      message:
        <Fragment>
          <div>Are you sure you want to post all charge back?</div>
          <div>This operation cannot be undone.</div>
        </Fragment>,
      primaryBtnTitle: "Yes",
      secondaryBtnTitle: "No"
    }))
  }

  const renderMenuItem = (item: MenuType, index: number) => {
    return (

      <List.Item key={index}>
        {
          item?.isLink ?
            <NavLink to={item?.link} className={(navData) => 'text-bold text-capitalize text-hover-underline text-hover-primary ' + (navData.isActive ? 'text-primary-color' : 'text-grey-color')}>
              {item?.name}
            </NavLink>
            :
            <span className="text-grey-color text-bold text-capitalize text-hover-underline text-hover-primary cursor-pointer" onClick={handleConfirmModal}>
              {item?.name}
            </span>
        }
      </List.Item>
    )
  }

  return (

    <Fragment>
      <div className="border-bottom border-secondary-color padding-t-1 padding-b-1">
        <List divided horizontal>
          {
            menu.map(renderMenuItem)
          }
        </List>
      </div>
    </Fragment>

  );
}

export default AdminMenuItems;