import { ModalsType } from "containers/ModalManager/ModalManager";
import { IChargeBack } from "interface";
import moment from "moment";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Table } from "semantic-ui-react";
import { modalOperation } from "store/actions";
import { deleteChargeBackRequest } from "store/rext";

type SearchProps = {
    practice_id: any;
    item: IChargeBack;
    isAdmin: boolean;
    checkboxes: any;
    toggleCheckbox: any;
    deleteResult: any
}

const SearchResultRows = ({ practice_id, item, isAdmin, checkboxes, toggleCheckbox, deleteResult }: SearchProps) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = item;

    const handleConfirmDelete = () => {
        dispatch(modalOperation.showModal(ModalsType.AlertModal, {
            onSave: () => {
                dispatch(modalOperation.hideModal());
                dispatch(deleteChargeBackRequest(practice_id, id));
                deleteResult();
            },
            onClose: () => {
                dispatch(modalOperation.hideModal());
            },
            message:
                <Fragment>
                    <p>Are you sure you want to delete this ChargeBack?</p>
                    <p>This operation cannot be undone.</p>
                </Fragment>,
            primaryBtnTitle: "Yes",
            secondaryBtnTitle: "No"
        }))
    }

    const editChargeBack = () => {
        navigate(`/practice/${practice_id}/charge-back/edit/${id}`);
    }

    return (
        <Fragment>
            <Table.Cell>
                {isAdmin && item?.reviewed === "No" ? <Button size='mini' secondary onClick={() => editChargeBack()}>Edit</Button> : ""}
            </Table.Cell>
            <Table.Cell>
                {isAdmin && item?.reviewed === "No" ? <Button size='mini' color="red" onClick={() => handleConfirmDelete()}>Delete</Button> : ""}
            </Table.Cell>
            <Table.Cell>{item.id}</Table.Cell>
            <Table.Cell textAlign='center'>
                {item?.reviewed === "No" && (isAdmin ? <Checkbox checked={checkboxes[id] || false} onChange={toggleCheckbox()} /> : <span className="text-bold text-x1lg text-danger-color">&#x2715;</span>)}
                {item?.reviewed === "Yes" && <span className="text-bold text-x2lg text-success-color">&#10003;</span>}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {item?.posted === "Yes" ? <span className="text-bold text-x2lg text-success-color">&#10003;</span> : <span className="text-bold text-x1lg text-danger-color">&#x2715;</span>}
            </Table.Cell>
            <Table.Cell>{item?.postedDate ? moment(item?.postedDate).format("DD/MM/YYYY") : "--"}</Table.Cell>
            <Table.Cell>{item?.staff || '--'}</Table.Cell>
            <Table.Cell>{item?.dateCreated ? moment(item?.dateCreated).format("DD/MM/YYYY") : "--"}</Table.Cell>
            <Table.Cell>{item.projectNumber}</Table.Cell>
            <Table.Cell>{item.revenueCodeOne}</Table.Cell>
            <Table.Cell>{item.officeName}</Table.Cell>
            <Table.Cell>{item.requestor}</Table.Cell>
            <Table.Cell>{item.researcherTime}</Table.Cell>
            <Table.Cell>{item.productOrService}</Table.Cell>
            <Table.Cell>{item.quantity}</Table.Cell>
            <Table.Cell>{(item.listPrice).toFixed(2)}</Table.Cell>
            <Table.Cell>{item.discountPercentage}</Table.Cell>
            <Table.Cell>{(item.totalDiscountedPrice).toFixed(2)}</Table.Cell>
            <Table.Cell>{item.discountReason}</Table.Cell>
            <Table.Cell>{item?.comments}</Table.Cell>
        </Fragment>
    )

}

export default SearchResultRows;