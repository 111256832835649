import { Link } from "react-router-dom";
import { APP_NAME } from "../../utils/global";
import './Header.scss';

const Header = () => {

    return (
        <header>
            <div className="title">
                <Link to="/">
                    <h3 className="cursor-pointer text-white-color">
                        {APP_NAME}
                    </h3>
                </Link>
            </div>
        </header>
    );
}

export default Header;