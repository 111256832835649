export const server = {
    practiceArea: "/practice-area",
    listUsers: "/user/:practiceId/:id",
    viewPostHistory: "/charge-back/histroy/:practiceId",
    priceList: "/price/:practiceId/:full",
    searchDropdown: "/search/dropdowns/:practiceId",
    chargeBack:"/charge-back/dropdowns/:practiceId",
    managePriceList:"/price/:practiceId/:id",
    searchList: "/search/:practiceId",
    manageChargeBack: "/charge-back/:practiceId/:id",
    postToAccounting: "/charge-back/post-to-accounting/:practiceId",
    bulkReview: "/charge-back/:practiceId/bulk-review"
}