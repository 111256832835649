import { transformSearchParamsToBody } from "containers/Search/utils"
import URLRoutes from "urlRoutes"
import { appRext, chargeBackDropdownRext, fetchChargeBackRext, manageChargeBackRext, managePriceListRext, manageUserDataRext, postToAccountingRext, searchDropdownRext } from "./rext"

export const fetchAppData = () => {
	return appRext.request({
		url: URLRoutes.server.practiceArea,
		method: "get"
	})
}

export const updateAppData = () => {
	return appRext.update({
		url: URLRoutes.server.practiceArea,
		method: "get"
	})
}

export const requstSearchDropdown = (practiceId: any) => {
	return searchDropdownRext.request({
		url: URLRoutes.server.searchDropdown,
		method: "get",
		urlParams: {
			practiceId
		}
	})
}


export const requstChargeBackDropdown = (practiceId: any) => {
	return chargeBackDropdownRext.request({
		url: URLRoutes.server.chargeBack,
		method: "get",
		urlParams: {
			practiceId
		}
	})
}

export const requestManagePriceList = (practiceId: any, id: any) => {
	return managePriceListRext.request({
		url: URLRoutes.server.managePriceList,
		method: "get",
		urlParams: {
			practiceId,
			id
		}
	})
}

export const managePriceChanges = (update: boolean, practiceId: any, body: any, id?: any,) => {

	if (update) {
		return managePriceListRext.update({
			url: URLRoutes.server.managePriceList,
			method: "put",
			urlParams: {
				practiceId,
				id,
			},
			body
		})
	} else {
		return managePriceListRext.update({
			url: URLRoutes.server.managePriceList,
			method: "post",
			urlParams: {
				practiceId,
				id: ""
			},
			body
		})
	}

}

export const manageChargeBackChanges = (update: boolean, practiceId: any, body: any, id?: any,) => {
	if (update) {
		return manageChargeBackRext.update({
			url: URLRoutes.server.manageChargeBack,
			method: "put",
			urlParams: {
				practiceId,
				id,
			},
			body
		})
	} else {
		return manageChargeBackRext.update({
			url: URLRoutes.server.manageChargeBack,
			method: "post",
			urlParams: {
				practiceId,
				id: ""
			},
			body
		})
	}
}

export const requestSearch = (practiceId: any, searchParams: URLSearchParams) => {
	return fetchChargeBackRext.request({
		url: URLRoutes.server.searchList,
		method: "post",
		urlParams: {
			practiceId
		},
		body: transformSearchParamsToBody(searchParams)
	})
}

export const requestManageChargeBack = (practiceId: any, id: any) => {
	return manageChargeBackRext.request({
		url: URLRoutes.server.manageChargeBack,
		method: "get",
		urlParams: {
			practiceId,
			id
		}
	})
}

export function deleteChargeBackRequest(practiceId: string, id: number) {
	return manageChargeBackRext.update({
		url: URLRoutes.server.manageChargeBack,
		method: "delete",
		urlParams: {
			practiceId,
			id
		}
	})
}

export function chargeBackBulkReviewUpdate(practiceId: string, body: any) {
	return manageChargeBackRext.update({
		url: URLRoutes.server.bulkReview,
		method: "put",
		urlParams: {
			practiceId
		},
		body: body
	})
}

export const postToAccounting = (practiceId: any) => {
	return postToAccountingRext.request({
		url: URLRoutes.server.postToAccounting,
		method: "post",
		urlParams: {
			practiceId
		}
	})
}

export const requestUserData = (practiceId: any, id: any) => {
	return manageUserDataRext.request({
		url: URLRoutes.server.listUsers,
		method: "get",
		urlParams: {
			practiceId,
			id
		}
	})
}
