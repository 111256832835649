import { Link } from "react-router-dom";
import { List } from "semantic-ui-react";
import { Fragment } from 'react';
import { useSelector } from "react-redux";
import { getAppState } from "store/selectors";
import { IPractice } from "interface";

import "./PracticeArea.scss";

const PracticeArea = () => {
  const { data : appState } = useSelector(getAppState);

  const renderPracticeArea = (practice: IPractice, index: number) => (
    <List.Item key={index}>
      <Link to={`/practice/${practice.id}/search`} className="practice-name text-bold text-lg text-primary-color text-hover-primary">
        {practice.name}
      </Link>
      {
        practice.role === 'ADMIN' ? <small className="margin-l-2 text-light text-secondary-color">(Admin)</small> : ''
      }
    </List.Item>
  )

  return (
    <Fragment>
      <div className="padding-l-14 padding-r-14 padding-t-6 padding-b-8">
        <h4>Please choose the practice you wish to use:  </h4>
        <List>
          {
            appState?.list?.map(renderPracticeArea)
          }
        </List>
      </div>
    </Fragment>
  );
};

export default PracticeArea;