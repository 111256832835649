import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Table } from "semantic-ui-react";
import { currencyFormat } from "utils/global";

const PriceListRow = ({ item, isAdminPath }: any) => {

    const navigate = useNavigate();

    const getValidEntryState = () => {
        if (item?.validForNewEntries) {
            return <span className="text-bold text-x2lg text-success-color">&#10003;</span>
        } else {
            return <span className="text-bold text-x1lg text-danger-color">&#x2715;</span>
        }
    }

    return (
        <Fragment>
            {
                isAdminPath ?
                    <Fragment>
                        <Table.Cell>
                            <Button size='mini' secondary onClick={() => navigate(`edit/${item.id || ""}`)}>Edit</Button>
                        </Table.Cell>
                    </Fragment>
                    : ''
            }
            <Table.Cell>{item?.id}</Table.Cell>
            <Table.Cell>{item?.name}</Table.Cell>
            <Table.Cell>{item?.description}</Table.Cell>
            <Table.Cell>{currencyFormat(item?.costPerUnit, 2, true)}</Table.Cell>
            <Table.Cell>{item?.discount || '--'}</Table.Cell>
            {
                isAdminPath ?
                    <Fragment>
                        <Table.Cell>{item?.accountingVendorCode}</Table.Cell>
                        <Table.Cell textAlign='center'>
                            {getValidEntryState()}
                        </Table.Cell>
                        <Table.Cell>{item?.revenueCodeOne || '--'}</Table.Cell>
                        <Table.Cell>{item?.revenueCodeTwo || '--'}</Table.Cell>
                        <Table.Cell>{item?.revenueCodeOnePercent || '--'}</Table.Cell>
                    </Fragment>
                    : ''
            }
        </Fragment>
    )

}

export default PriceListRow;