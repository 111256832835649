import { createList } from "baseProvider/list";
import { getBaseUrl, getBaseUrlAPIKEY, getToken } from "store/selectors";
import moment from "moment";

export const userList = createList({
	getBaseUrl,
	getXAPIToken: getBaseUrlAPIKEY,
	getToken,
	identity: "userList",
	primaryKey: "id",
	itemsKeyName: "list",
	deleteMessage: "User removed",
	addMessage: "User added successfully",
	editMessage: "User updated successfully"
})


export const viewPostHistoryList = createList({
	getBaseUrl,
	getXAPIToken: getBaseUrlAPIKEY,
	getToken,
	identity: "viewPostHistoryList",
	primaryKey: "id",
	itemsKeyName: "list",
	transformationOfAllItems: (items: Array<any>) => {
		const raw: any = {};
		items.forEach((item: any) => {
			const { postedDate, postedBy } = item;
			const postedById = postedBy ? (postedBy as string).replace("@bcg.com", "").replace(".", "") : "";
			const identicalKey = `${postedDate}${postedById}`
			if (raw[identicalKey]) {
				raw[identicalKey].items.push(item)
			} else {
				raw[identicalKey] =
				{
					id: identicalKey,
					name: postedBy,
					timeString: moment.utc(postedDate).format("MM/DD/YYYY hh:mm:ss A"),
					items: [item]
				}
			}
		})

		return Object.values(raw);
	}
})

export const priceList = createList({
	getBaseUrl,
	getXAPIToken: getBaseUrlAPIKEY,
	getToken,
	identity: "priceList",
	primaryKey: "id",
	itemsKeyName: "list",
	addMessage: "Price list item added successfully",
	editMessage: "Price list item updated successfully"
})
