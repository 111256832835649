import { useEffect } from "react";
import { Button, Form } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { FormCheckbox, FormInput, FormTextArea } from "components/FormInputs";

const AddPriceForm = ({ priceData, isEdit, handleCancel, onSubmit, isLoading }: any) => {
  const { register, handleSubmit, setValue, reset, getValues, formState: { errors }, trigger } = useForm({});

  useEffect(() => {
    if(isEdit) {
      reset(priceData || {});
    } else {
      reset({
        validForNewEntries: true
      });
    }
  }, []);

  return (
    <Form size="small" onSubmit={handleSubmit(onSubmit)}>
      <FormInput
        label='Product/Service'
        name='name'
        errors={errors}
        register={register}
        validation={{ required: true }}
      />

      <FormInput
        label='Accounting Vendor Code'
        name='accountingVendorCode'
        errors={errors}
        register={register}
        validation={{ required: true }}
      />

      <FormInput
        label='Unit Cost'
        name='costPerUnit'
        type='number'
        min={0}
        register={register}
        message='Leave the above field blank for no default price.'
      />

      <FormInput
        label='Default Discount Percent'
        name='discount'
        type='number'
        min={0}
        max={100}
        register={register}
        message='Leave the above field blank for no default discount.'
      />

      <FormTextArea
        label='Description'
        name='description'
        register={register}
        rows={3}
      />

      <FormCheckbox
        label='Valid For New Entries'
        name='validForNewEntries'
        register={register}
        getValues={getValues}
        setValue={setValue}
        trigger={trigger}
      />

      <FormInput
        label='Revenue Code 1'
        name='revenueCodeOne'
        register={register}
      />

      <FormInput
        label='Revenue Code 2'
        name='revenueCodeTwo'
        register={register}
      />

      <FormInput
        label='Revenue Code 1 Percent'
        name='revenueCodeOnePercent'
        type='number'
        min={0}
        max={100}
        register={register}
      />

      <div className="margin-t-8 flex flex-justify-end">
        <div className="margin-r-2">
          <Button type="button" disabled={isLoading} size='tiny' onClick={handleCancel}>Cancel</Button>
        </div>
        <Button type='submit' loading={isLoading} size='tiny' primary>Save</Button>
      </div>
    </Form>
  );
};

export default AddPriceForm;