import URLRoutes from "urlRoutes";
import { priceList, userList, viewPostHistoryList } from "./list";


export function requestUserList(practiceId: string) {
  return userList.request({
    url: URLRoutes.server.listUsers,
    method: "get",
    urlParams: {
      practiceId,
      id: ""
    }
  })
}


export function deleteUserRequest(practiceId: string, id: number) {
  return userList.deleteRequest({
    url: URLRoutes.server.listUsers,
    method: "delete",
    urlParams: {
      practiceId,
      id
    }
  })
}

export function addEditUserRequest(update: boolean, practiceId: string, id: number, body: any) {
  if (update) {
    return userList.editRequest({
      url: URLRoutes.server.listUsers,
      method: "put",
      urlParams: {
        practiceId,
        id
      },
      body
    })
  } else {
    return userList.addRequest({
      url: URLRoutes.server.listUsers,
      method: "post",
      urlParams: {
        practiceId,
        id: ""
      },
      body
    })
  }
}


export function requestViewPostHistoryList(practiceId: string) {
  return viewPostHistoryList.request({
    url: URLRoutes.server.viewPostHistory,
    method: "get",
    urlParams: {
      practiceId
    }
  })
}

export function requestPriceList(practiceId: string, isFull: boolean) {
  return priceList.request({
    url: URLRoutes.server.priceList,
    method: "get",
    urlParams: {
      practiceId,
      full: isFull ? 'full' : ''
    }
  })
}
