import Download from "components/Download";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "semantic-ui-react";
import { chargeBackBulkReviewUpdate } from "store/rext";
import URLRoutes from "urlRoutes";
import { transformSearchParamsToBody } from "../utils";

const SearchResultsReviewButtons = ({ practice_id, isAdmin, checkboxes, searchList, position, searchListfetching, sortSearchList, searchParam, cancelChanges }: any) => {

    const dispatch = useDispatch();

    const saveChanges = () => {
        const selectedIds = Object.keys(checkboxes).filter(key => checkboxes[key]);
        if (!selectedIds.length || !practice_id) return;
        const payload = {
            reviewIds: selectedIds
        }
        dispatch(chargeBackBulkReviewUpdate(practice_id, payload));
        toast.info("Reviewed charge backs saved successFully");
        searchList.forEach((el: any) => {
            if (checkboxes[el.id]) {
                el['reviewed'] = 'Yes';
            }
        })
    }

    const reviewButtons = () => {
        return (
            <Fragment>
                {isAdmin ?
                    <>
                        <Button size="mini" onClick={cancelChanges} secondary>
                            CANCEL Changes To Reviewed Checkboxes
                        </Button>
                        <Button size="mini" onClick={saveChanges} secondary>
                            SAVE Changes To Reviewed  Checkboxes
                        </Button>
                    </>
                    : ""
                }
            </Fragment>
        )
    }

    if (searchListfetching || !sortSearchList.length) {
        return null;
    }

    if (position === 'top') {
        return (
            <div className="flex flex-justify-between margin-b-6">
                <div className="flex-justify-start">
                    {
                        reviewButtons()
                    }
                </div>

                <div className="flex-justify-end">
                    <Download body={transformSearchParamsToBody(searchParam)} method="post" urlParams={{ practiceId: practice_id }} queryParams={undefined!} url={`${URLRoutes.server.searchList}/export`} />
                </div>
            </div>
        )
    } else {
        return (
            <div className="margin-t-6">
                {
                    reviewButtons()
                }
            </div>
        )
    }

}


export default SearchResultsReviewButtons;