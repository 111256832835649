import { SagaIterator } from "redux-saga";
import { all, fork } from "redux-saga/effects";
import { priceList, userList, viewPostHistoryList } from "store/list";
import { appRext, chargeBackDropdownRext, fetchChargeBackRext, manageChargeBackRext, managePriceListRext, manageUserDataRext, postToAccountingRext, searchDropdownRext } from "store/rext";

export default function* root(): SagaIterator {
  yield all([
    fork(appRext.saga),
    fork(userList.saga),
    fork(viewPostHistoryList.saga),
    fork(priceList.saga),
    fork(searchDropdownRext.saga),
    fork(chargeBackDropdownRext.saga),
    fork(managePriceListRext.saga),
    fork(fetchChargeBackRext.saga),
    fork(postToAccountingRext.saga),
    fork(manageChargeBackRext.saga),
    fork(manageUserDataRext.saga)
  ])
}