import { LoginCallback } from "@okta/okta-react";
import { Navigate, Route, Routes } from "react-router-dom";
import { SecureRoute } from "../HOC/SecureRoute";
import PageNotFound from "../containers/NotFound";
import Search from "../containers/Search";
import PracticeArea from "../containers/PracticeArea";
import UserList from "../containers/UserList";
import Menu from "../components/MenuItems/Menu";
import PriceList from "../containers/PriceList";
import ChargeBack from "../containers/ChargeBack";
import PostHistory from "../containers/PostHistory";
import AddUser from "../containers/UserList/AddUser";
import AddPriceListItem from "../containers/PriceList/AddPriceListItem";
import Loader from "../components/Loading/Loading";
import { AdminRoute } from "../HOC/AdminRoute";
import SearchResults from "../containers/Search/SearchResults";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<SecureRoute />}>
        <Route index element={<PracticeArea />} />
        <Route path='practice/:practice_id' element={<Menu />}>
          <Route path='' element={<Navigate to='search' />} />
          <Route path='search'>
            <Route index element={<Search />} />
            <Route path='results' element={<SearchResults />} />
          </Route>
          <Route path='price-list' element={<PriceList />} />
          <Route path='charge-back'>
            <Route index element={<ChargeBack />} />
            <Route path='edit/:charge_back_id' element={<ChargeBack />} />
          </Route>
          {/* Admin Routes */}
          <Route path='' element={<AdminRoute />}>
            <Route path='user-list'>
              <Route index element={<UserList />} />
              <Route path='add' element={<AddUser />} />
              <Route path='edit/:user_id' element={<AddUser />} />
            </Route>
            <Route path='post-history' element={<PostHistory />} />
            <Route path='admin-price-list'>
              <Route index element={<PriceList />} />
              <Route path='add' element={<AddPriceListItem />} />
              <Route path='edit/:price_id' element={<AddPriceListItem />} />
            </Route>
          </Route>
        </Route>
        <Route path='*' element={<PageNotFound />} />
      </Route>
      <Route path='login/callback' element={<LoginCallback loadingElement={<Loader />} />} />
    </Routes>
  );
};

export default AppRoutes;