import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { requestManagePriceList, managePriceChanges } from "store/rext";
import { getPriceListItems } from "store/selectors";
import usePrevious from "HOC/UsePrevious";
import { toast } from "react-toastify";
import AddPriceForm from "./AddPriceForm";
import Loading from "components/Loading";

const AddPriceListItem = () => {
  const { practice_id, price_id } = useParams();
  const dispatch = useDispatch();
  const { data : priceData, isUpdated: isPriceUpdating, error: updationError, fetching } = useSelector(getPriceListItems)
  const navigate = useNavigate();
  const [isEdit, setEdit] = useState(false);
  const wasPriceUpdating = usePrevious(isPriceUpdating);

  const onSubmit = (data: any) => {
    dispatch(managePriceChanges(isEdit, practice_id, data, price_id));
  }

  const navigateBack = () => {
    navigate(-1);
  }

  useEffect(() => {
    if(price_id) {
      dispatch(requestManagePriceList(practice_id, price_id));
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [ price_id ])


  useEffect(() => {
    if(wasPriceUpdating && !isPriceUpdating && !updationError) {
      toast.info(isEdit ? "Price Updated SuccessFully": "Price has been added");
      navigateBack();
    }
  }, [wasPriceUpdating, isPriceUpdating])


  return (
      <div className="width-30">
        <h5 className="margin-b-9">{isEdit ? 'Edit an existing PriceList Item:' : 'Add a new PriceList Item:'}</h5>
        {(!isEdit || (isEdit && !fetching)) && <AddPriceForm
          priceData={isEdit ? priceData : {}}
          isEdit={isEdit}
          handleCancel={navigateBack}
          onSubmit={onSubmit}
          isLoading={isPriceUpdating} />
        }
        {
          fetching && <Loading />
        }
      </div>
  );
};

export default AddPriceListItem;