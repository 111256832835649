import { Table } from 'semantic-ui-react';
import TableLoading from "components/Loading/TableLoading";
import SortableHeader from "components/SortableHeader.tsx/SortableHeader";
import { Column } from 'interface';

interface Props {
  columns: Column[];
  rows: any;
  noDataMessage: string;
  children?: any
  fetching: boolean;
}


function TableComponent({ columns, fetching, rows, noDataMessage, children }: Props) {
  return (
    <div className="width-100 overflow-auto">
      {
        fetching && <TableLoading columns={columns} />
      }
      {
        !fetching && !rows.length && <p>{noDataMessage}</p>
      }
      {
        !fetching && rows.length ? <Table size='small' sortable celled>
          <SortableHeader columns={columns} />
          <Table.Body>
            {children}
          </Table.Body>
        </Table> : ''
      }
    </div>
  )
}

export default TableComponent;