import { useEffect } from "react";
import { Button, Form } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { useOutletContext, useParams } from "react-router-dom";
import { IPractice } from "../../interface";
import { FormCheckbox, FormDatePicker, FormDropdown, FormInput, FormTextArea } from "components/FormInputs";
import { currencyFormat } from "utils/global";
import { useDispatch, useSelector } from "react-redux";
import { getAllChargeBackDropdown, getPriceListState } from "store/selectors";
import { requestManagePriceList, requstChargeBackDropdown } from "store/rext";
import { requestPriceList } from "store/list";

const AddChargeBackForm = ({ chargeBackData, isEdit, handleCancel, onSubmit, isLoading }: any) => {

    const { practice_id } = useParams();
    const dispatch = useDispatch();
    const { data: dropdownState } = useSelector(getAllChargeBackDropdown);
    const { data: priceList } = useSelector(getPriceListState);

    useEffect(() => {
        dispatch(requstChargeBackDropdown(practice_id));
        if (practice_id) {
            dispatch(requestPriceList(practice_id, false));
        }
    }, [dispatch])

    const { register, handleSubmit, setValue, reset, getValues, formState: { errors }, trigger } = useForm({});
    const practice = useOutletContext<IPractice>();

    const changeService = (data: any) => {
        const { name, value } = data;
        dispatch(requestManagePriceList(practice_id, value));
        setValue(name, value);
        const priceListItem = priceList.find(el => el.id == value);
        setValue('quantity', 1);
        setValue('total', currencyFormat(priceListItem?.costPerUnit, 2, true));
        setValue('discountPercentage', priceListItem?.discount || 0);
        const discountPrice = priceListItem?.costPerUnit - (priceListItem?.discount ? (priceListItem?.discount / 100) * priceListItem?.costPerUnit : 0);
        setValue('finalDiscountPrice', discountPrice);
        trigger(["productOrService"]);
    }

    const changeQuantity = (e: any) => {
        const quantity: number = parseInt(e.target.value);
        setValue('quantity', quantity);
        const values = getValues();
        const priceListItem = priceList.find(el => el.id == values.productOrService);
        const total = priceListItem && priceListItem?.costPerUnit && quantity ? priceListItem?.costPerUnit * quantity : 0;
        setValue('total', currencyFormat(total, 2, true));
        const discountPrice = total - (values?.discountPercentage ? (values?.discountPercentage / 100) * total : 0);
        setValue('finalDiscountPrice', discountPrice);
        trigger(["quantity"]);
    }

    const changeDiscount = (e: any) => {
        const discount: number = parseInt(e.target.value);
        const values = getValues();
        const total = values?.total ? Number(values?.total.replace(/[$,]+/g, "")) : 0;
        const discountPrice = total - (discount ? (discount / 100) * total : 0);
        setValue('finalDiscountPrice', discountPrice);
        trigger(["finalDiscountPrice"]);
    }

    const changeFinalPrice = (e: any) => {
        const finalDiscountPrice: number = parseInt(e.target.value);
        const values = getValues();
        const total = values?.total ? Number(values?.total.replace(/[$,]+/g, "")) : 0;
        if (finalDiscountPrice < total) {
            const discount = (1 - finalDiscountPrice / total) * 100;
            setValue('discountPercentage', parseFloat(discount.toFixed(2)));
        } else {
            setValue('discountPercentage', 0);
            setValue('finalDiscountPrice', parseFloat(total.toFixed(2)));
        }
    }

    useEffect(() => {
        if (isEdit) {
            reset({
                ...chargeBackData,
                reviewed: chargeBackData.reviewed,
                date: new Date(chargeBackData.date),
                productOrService: (chargeBackData.productOrService).toString(),
                total: currencyFormat(chargeBackData.totalPrice, 2, true),
                finalDiscountPrice: chargeBackData.discountPrice
            });
        } else {
            reset({
                reviewed: false,
                date: new Date(),
                projectNumber: '',
                officeCode: '',
                requestor: '',
                researcherTime: '',
                productOrService: '',
                quantity: 0,
                total: 0,
                discountPercentage: 0,
                finalDiscountPrice: 0,
                discountReason: '',
                comments: ''
            });
        }
    }, []);

    return (

        <Form size="small" onSubmit={handleSubmit(onSubmit)}>
            {
                practice?.role === 'ADMIN' &&
                <FormCheckbox
                    label='Reviewed'
                    name='reviewed'
                    register={register}
                    getValues={getValues}
                    setValue={setValue}
                    trigger={trigger}
                />
            }

            <FormDatePicker
                label='Date'
                name='date'
                register={register}
                getValues={getValues}
                setValue={setValue}
                trigger={trigger}
            />

            <FormInput
                label='Project #'
                name='projectNumber'
                errors={errors}
                register={register}
                validation={{ required: true }}
            />

            <FormDropdown
                label='Office'
                name='officeCode'
                options={dropdownState.locations || []}
                errors={errors}
                register={register}
                validation={{ required: true }}
                getValues={getValues}
                setValue={setValue}
                trigger={trigger}
            />

            <FormInput
                label='Requestor'
                name='requestor'
                errors={errors}
                register={register}
                validation={{ required: true }}
            />

            <FormInput
                label='Researcher Time'
                name='researcherTime'
                register={register}
            />

            <FormDropdown
                label='Product or Service'
                name='productOrService'
                options={dropdownState.services || []}
                errors={errors}
                register={register}
                validation={{ required: true }}
                getValues={getValues}
                setValue={setValue}
                trigger={trigger}
                handleChange={(e: any, data: any) => changeService(data)}
            />

            <FormInput
                label='Quantity'
                name='quantity'
                register={register}
                type="number"
                min={1}
                errors={errors}
                validation={{ required: true }}
                detectChange={true}
                onChange={(e: any) => changeQuantity(e)}
            />

            <FormInput
                label='Total Price'
                name='total'
                register={register}
                errors={errors}
                validation={{ required: true }}
                readOnly={true}
            />

            <FormInput
                label='Discount %'
                name='discountPercentage'
                register={register}
                type="number"
                min={0}
                max={100}
                errors={errors}
                validation={{ required: true }}
                detectChange={true}
                onChange={(e: any) => changeDiscount(e)}
            />

            <FormInput
                label='Final Discount Price'
                name='finalDiscountPrice'
                register={register}
                type="number"
                min={0}
                errors={errors}
                validation={{ required: true }}
                detectChange={true}
                onChange={(e: any) => changeFinalPrice(e)}
            />

            <FormInput
                label='Discount Reason'
                name='discountReason'
                register={register}
            />

            <FormTextArea
                label='Comments'
                name='comments'
                register={register}
                rows={3}
            />

            <div className="margin-t-8 flex flex-justify-end">
                <div className="margin-r-2">
                    <Button type="button" disabled={isLoading} size='tiny' onClick={handleCancel}>Cancel</Button>
                </div>
                <Button type='submit' loading={isLoading} size='tiny' primary>Save</Button>
            </div>
        </Form>

    );
}

export default AddChargeBackForm;