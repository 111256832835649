import { Fragment } from "react";
import { Form } from "semantic-ui-react";
import { FormErrors } from "./FormErrors";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
export function FormDatePicker({ onChange, ...props }: any) {

  const { label, name, errors, register, validation, getValues, setValue, trigger, handleChange } = props;
  const value = getValues()[name];

  const handleDateChange = handleChange || (
    async (date: any) => {
      setValue(name, date);
      await trigger([name]);
    }
  )

  return (
    <Fragment>
      <Form.Field error={errors && !!errors[name]}>
        <label className={validation && validation.required && 'required'}>{label}</label>
        <DatePicker placeholderText={label} selected={value} fixedHeight={true} {...register(name, validation)}
          onChange={handleDateChange}
          isClearable />
        {
          errors &&
          <FormErrors
            label={label}
            name={name}
            errors={errors}
          />
        }

      </Form.Field>
    </Fragment>
  );
}
