import OktaAuth from "@okta/okta-auth-js";
import { getListState } from "baseProvider/list";
import { getRextState } from "baseProvider/rext";
import { ApplicationState, ModalState } from "store/reducers";

export function getModalState(state: ApplicationState): ModalState {
  return state.modalState
}

export function getOktaAuthState(state: ApplicationState): OktaAuth {
  return state.applicationContext.okatAuth
}

export function getToken(state: ApplicationState): string {
  const { okatAuth } = state.applicationContext;
  if (okatAuth) {
    return `Bearer ${okatAuth.getAccessToken()!}`;
  }
  return "";
}

export function getBaseUrl(state: ApplicationState): string {
  return process.env.REACT_APP_BASE_API_URL!;
}

export function getBaseUrlAPIKEY(state: ApplicationState) {
  return process.env.REACT_APP_X_API_KEY!;
}


export function getAppState(state: ApplicationState) {
  return getRextState(state.appState, undefined!)
}


export function getUserListState(state: ApplicationState) {
  return getListState(state.userListState, [])
}

export function getViewPostHistoryState(state: ApplicationState) {
  return getListState(state.viewPostHistoryState, [])
}

export function getPriceListState(state: ApplicationState) {
  return getListState(state.priceListState, [])
}

export function getAllSearchDropdown(state: ApplicationState) {
  return getRextState(state.searchDropdownState, {})
}

export function getAllChargeBackDropdown(state: ApplicationState) {
  return getRextState(state.chargeBackDropdownState, {})
}

export function getPriceListItems(state:ApplicationState){
  return getRextState(state.managePriceListState,{})
}

export function getChargeBackItems(state:ApplicationState){
  return getRextState(state.manageChargeBackState,{})
}

export function getSearchtems(state:ApplicationState){
  return getRextState(state.fetchChargeBackState,{})
}

export function getUserData(state:ApplicationState){
  return getRextState(state.manageUserState,{})
}