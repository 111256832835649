import moment from "moment";

export function transformSearchParamsToBody(searchParams: URLSearchParams) {
  const body: any = {};
  searchParams.forEach((value, key) => {
    if (value.length && key !== 'col' && key !== 'dir') {
      if (key === "from") {
        body[key] = moment(parseInt(value, 10)).startOf("date").toDate().getTime();
      } else if (key === "to") {
        body[key] = moment(parseInt(value, 10)).endOf("day").toDate().getTime();
      } else if (key === "userId" || key === "officeCode" || key === "review" || key === "post") {
        if (value !== "ALL") {
          body[key] = value
        }
      } else if (key === "services") {
        body[key] = value.split(",").map(item => parseInt(item, 10)).filter(item => !isNaN(item))
      } else if (key === "postDate") {
        body.postDateStart = moment(parseInt(value, 10)).startOf("day").toDate().getTime();
        body.postDateEnd = moment(parseInt(value, 10)).endOf("day").toDate().getTime();
      }
      else if (key === 'project') {
        body['projectText'] = value;
      } else if (key === 'requestor') {
        body['requestorText'] = value;
      } else {
        body[key] = value;
      }
    }
  });
  return body;
}