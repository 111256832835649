import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet } from 'react-router-dom';

import Footer from "../components/Footer";
import Header from '../components/Header';
import Loading from '../components/Loading';
import { getAppState } from 'store/selectors';
import { fetchAppData } from 'store/rext';

export const SecureRoute = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const dispatch = useDispatch();
  const { fetching, data: appState } = useSelector(getAppState);

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
    else if(authState?.isAuthenticated) {
      if (!appState) {
        dispatch(fetchAppData())
      }
    }
  }, [oktaAuth, authState]);

  if (!authState || !authState?.isAuthenticated || fetching) {
    return (<Loading />);
  }

  return (
    <Fragment>
      <Header />
      {appState && <div className='page-content'>
        <Outlet />
      </div>
      }
      <Footer />
    </Fragment>
  );
}
