import { combineReducers, Reducer } from 'redux';
import { infoReducer, itemsReducer } from './reducer';
import { listActionFunctions, createIdentityAction } from './actions';
import {
  IListKeys,
  IListActionCreators,
  IListParams,
  IListAction,
  IListInfo,
  IList,
  ActionIdentity,
  IListItems,
} from './interface';
import { defaultListInfo, defaultListItems } from './defaultState';
import createSagaEvent from './saga';

export const getListActionCreators = (keys: IListKeys, actionidentity: ActionIdentity): IListActionCreators => {
  return {
    request: (params: IListParams) =>
      listActionFunctions.request(
        { actions: actionidentity.LIST_FETCH, keys }, params
      ),
    deleteRequest: (params: IListParams) =>
      listActionFunctions.request(
        { actions: actionidentity.DELETE_ITEM, keys }, params, keys.deleteMessage
      ),
    multiAddRequest: (params: IListParams) =>
      listActionFunctions.request(
        { actions: actionidentity.MULTIPLE_ADD_ITEMS, keys }, params, keys.multiAddMessage
      ),
    addRequest: (params: IListParams) =>
      listActionFunctions.request(
        { actions: actionidentity.ADD_ITEM, keys }, params, keys.addMessage
      ),
    editRequest: (params: IListParams) =>
      listActionFunctions.request(
        { actions: actionidentity.EDIT_ITEM, keys }, params, keys.editMessage
      )
  };
};
export const list = (
  items: Reducer<IListItems, IListAction>,
  info: Reducer<IListInfo, IListAction>,
  requestPaginationActionCreators: IListActionCreators,
  saga: any,
): IList => ({
  reducers: combineReducers({
    info,
    items
  }),
  ...requestPaginationActionCreators,
  saga,
});
export const createList = (keys: IListKeys): IList => {
  const { identity } = keys;
  const actionidentity = createIdentityAction(identity);
  const info = infoReducer(actionidentity, defaultListInfo);
  const items = itemsReducer(actionidentity, defaultListItems);
  const listActionCreators = getListActionCreators(keys, actionidentity);
  const sagaEvent = createSagaEvent(actionidentity);
  return list(items, info, listActionCreators, sagaEvent);
};