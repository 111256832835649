import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getChargeBackItems } from "store/selectors";
import { manageChargeBackChanges, requestManageChargeBack } from "store/rext";
import AddChargeBackForm from "./AddChargeBackForm";
import Loading from "components/Loading";
import UsePrevious from "HOC/UsePrevious";
import { toast } from "react-toastify";
import moment from "moment";

function ChargeBack() {

    const { practice_id, charge_back_id } = useParams();
    const dispatch = useDispatch();
    const { data: chargeBackData, isUpdated: isChargeBackUpdating, error: updationError, fetching } = useSelector(getChargeBackItems);
    const [isEdit, setEdit] = useState(false);
    const navigate = useNavigate();
    const wasChargeBackUpdating = UsePrevious(isChargeBackUpdating);

    const onSubmit = (data: any) => {
        const payload = {
            ...data,
            date: moment(data.date).valueOf(),
            projectNumber: (data.projectNumber).trim(),
            total:  Number(data.total.replace(/[$,]+/g,""))
        }
        dispatch(manageChargeBackChanges(isEdit, practice_id, payload, charge_back_id));
    }

    const navigateBack = () => {
        navigate(-1);
    }

    useEffect(() => {
        if (charge_back_id) {
            dispatch(requestManageChargeBack(practice_id, charge_back_id));
            setEdit(true);
        } else {
            setEdit(false);
        }
    }, [charge_back_id]);

    useEffect(() => {
        if (wasChargeBackUpdating && !isChargeBackUpdating && !updationError) {
            toast.info(isEdit ? "Charge Back Updated SuccessFully" : "Charge Back has been added");
            navigate(`/practice/${practice_id}/search/results`);
        }
    }, [wasChargeBackUpdating, isChargeBackUpdating])

    return (

        <div className="width-30">
            <h5 className="margin-b-9">{isEdit ? 'Edit an existing ChargeBack:' : 'Add a new ChargeBack:'}</h5>
            {(!isEdit || (isEdit && !fetching)) && <AddChargeBackForm
                chargeBackData={isEdit ? chargeBackData : {}}
                isEdit={isEdit}
                handleCancel={navigateBack}
                onSubmit={onSubmit}
                isLoading={isChargeBackUpdating} />
            }
            {
                fetching && <Loading />
            }
        </div>

    );
}

export default ChargeBack;