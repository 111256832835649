import { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { APP_NAME } from '../../utils/global';
import { getAppState } from 'store/selectors';
import './Footer.scss';
import { modalOperation } from 'store/actions';
import { ModalsType } from 'containers/ModalManager/ModalManager';

const Footer = () => {

  const dispatch = useDispatch();
  const { data: appState } = useSelector(getAppState);

  const handleAboutModal = () => {
    dispatch(
      modalOperation.showModal(ModalsType.AboutModal, {
        title: APP_NAME,
        primaryBtnTitle: "Close",
        onSave: () => {
          dispatch(modalOperation.hideModal())
        }
      })
    )
  }

  return (
    <Fragment>
      <footer className="footer">
        <div className="footer-body flex flex-column flex-align-center">
          © Copyright 2001-{new Date().getFullYear()} - The Boston Consulting Group
          <br />
          <div className='flex-row'>
            <span className="footer-body__link cursor-pointer text-underline" onClick={handleAboutModal}>About {APP_NAME}</span>
            <span className='margin-l-6'>User ID: {appState?.user?.id}</span>
          </div>
        </div>
      </footer>
    </Fragment>
  );
}

export default Footer;