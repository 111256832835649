import { Button, Modal } from "semantic-ui-react";
import { ModalHeader } from "./Common";
import { Fragment } from "react";

interface Props {
  hideModal: () => void;
  onClose: () => void;
  onSave: () => void;
  title: string;
  primaryBtnTitle?: string;
}


function AboutModal(props: Props) {
  const {
    title,
    primaryBtnTitle,
    hideModal
  } = props;

  return (
    <Modal
      dimmer
      open
      onClose={hideModal}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <ModalHeader title={title} toggle={hideModal} />
      <Modal.Content>
        <Fragment>
        <p>
          ChargeTrack was designed by the ITPA and funded jointly by the ITPA and the T&CPA.
        </p>
        <p>
          Contact <a href="mailto:Sharma.Abhishek@bcg.com">Abhishek Sharma</a> or <a href="mailto:Logiudice.David@bcg.com">David Logiudice</a> for comments or suggestions.
        </p>
        <p>
          ChargeTrack was implemented by <a href="mailto:Dave@jumbosolutions.com"> David Logiudice of JumboSolutions, Inc</a>.
        </p>
        </Fragment>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={props.onSave}>
          {primaryBtnTitle || "OK"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
export default AboutModal;
