import TableComponent from "components/TableComponent";
import { Column } from "interface";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Table } from 'semantic-ui-react';
import { requestViewPostHistoryList } from "store/list";
import { getViewPostHistoryState } from "store/selectors";
import { currencyFormat } from "utils/global";

interface IPostHistoryItem {
  postedBy: string;
  postedDate: string;
  projectCode: string;
  total: number;
  list: Array<{
    projectNumber: string;
    revenueCode: string;
    total: number;
  }>
}

interface IPostHistory {
  id: string;
  timeString: string;
  name: string;
  items: IPostHistoryItem[]
}

const columns: Column[] = [
  { name: 'Accounting Vendor Code', value: 'accounting_vendor_code', sort: false },
  { name: 'Project Number', value: 'project_number', sort: false },
  { name: 'Revenue Code', value: 'revenue_code', sort: false },
  { name: 'Cost Summary', value: 'cost_summary', sort: false }
];

const PostHistory = () => {
  const { practice_id } = useParams();
  const { data: postHistory, fetching: postHistoryFetching } = useSelector(getViewPostHistoryState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (practice_id) {
      dispatch(requestViewPostHistoryList(practice_id));
    }
  }, [practice_id])

  const renderRevenueItem = (projectCode: string) => ({ projectNumber, revenueCode, total: revenueTotal }: any, index: number) => {
    return (
      <Table.Row key={`${projectNumber}${revenueCode}`}>
        <Table.Cell>{projectCode}</Table.Cell>
        <Table.Cell>{projectNumber}</Table.Cell>
        <Table.Cell>{revenueCode}</Table.Cell>
        <Table.Cell>{currencyFormat(revenueTotal, 2)}</Table.Cell>
      </Table.Row>
    )
  }

  const renderPostHistoryItem = (item: IPostHistoryItem, index: number) => {
    const { list, total, projectCode, postedDate } = item;
    const revenueFunc = renderRevenueItem(projectCode);
    return (
      <Fragment key={`${postedDate}${index}`}>

        {
          list.map(revenueFunc)
        }
        {
          list.length >= 2 ?
            <Fragment>
              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell><b>{currencyFormat(total, 2)}</b></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell colSpan={4}>&nbsp;</Table.Cell>
              </Table.Row>
            </Fragment>
            :
            <Table.Row>
              <Table.Cell colSpan={4}>&nbsp;</Table.Cell>
            </Table.Row>
        }

      </Fragment>
    )
  }


  const renderPostHistory = (history: IPostHistory, index: number) => {
    const { id, items, name, timeString } = history;
    return (
      <Fragment key={id}>
        <Table.Row active>
          <Table.Cell colSpan='4' textAlign='center'> The following record(s) posted on <b>{timeString}</b> by: <b>{name}</b></Table.Cell>
        </Table.Row>
        {
          items.map(renderPostHistoryItem)
        }
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div className="width-70">
        <h5 className="margin-b-9">List of Posted Entries :</h5>
        <TableComponent noDataMessage="There are no items in the Post Track list."
          fetching={!!postHistoryFetching} columns={columns} rows={postHistory} >
          {
            postHistory?.map(renderPostHistory)
          }
        </TableComponent>
      </div>
    </Fragment>
  );
};

export default PostHistory;